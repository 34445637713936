const cardSlider = () =>{
    $('.js-slider-card').slick({
        centerMode: true,
        variableWidth: true,
        centerPadding: '30px',
        slidesToShow: 3,
        focusOnSelect: true,
        draggable: false,
        autoPlay: false,
        infinite: false

      })
}

const animateCard = () => {
    const controller = new ScrollMagic.Controller();
    $('.descr-row').each(function () {
        const tlScroll = new TimelineMax();
        const img = $(this).find('.descr-img-wrap');
        // const elHeight = $(this).outerHeight();
        if (!$(this).hasClass('reverse')) {
            tlScroll
                .to(img, 0.6, {
                    top: '-17%',
                    left: '-14%',
                    ease: Power0.easeNone,
                });
            new ScrollMagic.Scene({
                triggerElement: this,
                triggerHook: 0.5,
            })
                .setTween(tlScroll)
                .addTo(controller);
        } else {
            tlScroll
                .to(img, 0.5, {
                    top: '-17%',
                    left: '14%',
                    ease: Power0.easeNone,
                });
            const scene = new ScrollMagic.Scene({
                triggerElement: this,
                triggerHook: 0.5,
            })
                .setTween(tlScroll)
                .addTo(controller);
        }
    });
};

const reviewSlider = function initProductSlider() {
    const slider = $('.js-review-slider');


    $(slider).on({
        afterChange: (event, slick, currentSlide) => {
            $('.benefit-item').removeClass('is-active');
            $('.benefit-item').eq(currentSlide).addClass('is-active');

        },
    }).slick({
        fade: true,
        infinite: true,
        slideToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 3000,
        speed: 300,
        appendArrows: $('.review-arrow'),
    });

    $('.benefit-item').click((e) => {
        $(slider).slick('slickPause');
        const index = $('.benefit-item').index(e.currentTarget);
        $(slider).finish().slick('slickGoTo', index);
        $('.benefit-item').removeClass('is-active');
        $(this).addClass('is-active');
    });
};

const animateGreetScreen = () => {
    // const controller = new ScrollMagic.Controller();
    // const w = $(window).outerWidth();
    // let maskX = '0%';
    // let maskScale = 1.35;
    // // if (w < 430) {
    // //     maskX = '0%';
    // //     maskScale = 1.28;
    // // }
    // const tlBubble = new TimelineMax()
    //     .to('.pill-bubble--orange', 6, {
    //         y: -6,
    //         repeat: -1,
    //         yoyo: true,
    //         transformOrigin: '50% 50%',
    //     })
    //     .to('.pill-bubble--orange', 6, {
    //         x: 5,
    //         repeat: -1,
    //         yoyo: true,
    //         transformOrigin: '50% 50%',
    //     }, '-=3')
    //     .to('.pill-bubble--2', 6, {
    //         y: '+=10',
    //         repeat: -1,
    //         yoyo: true,
    //     }, -6)
    //     .to('.pill-bubble--2', 6, {
    //         x: '+=6',
    //         repeat: -1,
    //         yoyo: true,
    //     }, -6)
    //     .to('.pill-bubble--3', 6, {
    //         y: '-=5',
    //         repeat: -1,
    //         yoyo: true,
    //     }, -6)
    //     .to('.pill-bubble--3', 6, {
    //         x: '+=6',
    //         repeat: -1,
    //         yoyo: true,
    //     }, -6)
    //     .to('.pill-bubble--4', 6, {
    //         y: '-=5',
    //         repeat: -1,
    //         yoyo: true,
    //     }, -6)
    //     .to('.pill-bubble--4', 6, {
    //         x: '-=6',
    //         repeat: -1,
    //         yoyo: true,
    //     }, -3)
    //     .to('.pill-bubble--5', 6, {
    //         y: '-=5',
    //         repeat: -1,
    //         yoyo: true,
    //     }, -6)
    //     .to('.pill-bubble--5', 6, {
    //         x: '+=6',
    //         repeat: -1,
    //         yoyo: true,
    //     }, -3)
    //     .to('.pill-bubble--6', 6, {
    //         y: '-=5',
    //         repeat: -1,
    //         yoyo: true,
    //     }, -6)
    //     .to('.pill-bubble--6', 6, {
    //         x: '-=6',
    //         repeat: -1,
    //         yoyo: true,
    //     }, -3);
    // new ScrollMagic.Scene({
    //     triggerElement: '#trigger',
    //     triggerHook: 0.2,
    //     offset: 500,
    // })
    //     .setTween(tlBubble)
    //     .addTo(controller);




    //     if($('body').hasClass('finish')){
    //         const tl = new TimelineMax()
    //         .to('.greet__header', 0.7, {
    //             autoAlpha: 0,
    //             y: '-30%',
    //         })
    //         .to('.greet__text', 0.7, {
    //             autoAlpha: 0,
    //             y: '10%',
    //         }, '-=0.7')
    //         .to('.greet .scroll', 0.7, {
    //             autoAlpha: 0,
    //         }, '-=0.7')
    //         .to('.greet__mask', 2, {
    //             scaleX: maskScale,
    //             scaleY: maskScale,
    //             x: maskX,
    //             // y: maskY,
    //             ease: Power0.easeOut,
    //         }, '-=0.5')
    //         .to($('.greet'), 2, {
    //             autoAlpha: 0,
    //         })
    //         .to($('.header--main'), 0.3, {
    //             autoAlpha: 1,
    //         })
    //         .to('.hero__text .title', 0.5, {
    //             y: 0,
    //             autoAlpha: 1,
    //             ease: Power0.easeNone,
    //         }, '-=0.1')
    //         .to('.hero__text p', 0.5, {
    //             y: 0,
    //             autoAlpha: 1,
    //             ease: Power0.easeNone,
    //         }, '-=0.1')
    //         .to($('.greet'), 2, {
    //             autoAlpha: 0,
    //         });
    //         // let duration = '250%'
    //    let scene =         new ScrollMagic.Scene({
    //     triggerElement: '#trigger',
    //     triggerHook: 0,
    //     duration: '250%',
    // })
    //     .setTween(tl)
    //     .setPin('.hero', {
    //         pushfollowers: false,
    //     })
    //     .addTo(controller);
    //     scene.on("end", function (event) {
    //         $('body').removeClass('finish')
    //         tl.kill()
    //         // duration = '30%'
    //         scene.destroy(true);
    //         window.scrollTo(0,0)
    //         // scene = null;

    //     });
    //     }


};

const animateSite = () => {
    const controller = new ScrollMagic.Controller();
    const tl3 = new TimelineMax();
    const tl4 = new TimelineMax();
    const tl6 = new TimelineMax();
    const tl7 = new TimelineMax();
    const tl8 = new TimelineMax();
    const tl9 = new TimelineMax();

    /* * Hero title disappear * */
    // const tlHero = new TimelineMax()
    //     .to('.hero__text .title', 0.5, {
    //         y: '-10%',
    //         autoAlpha: 0,
    //         ease: Power0.easeNone,
    //     })
    //     .to('.hero__text p', 0.5, {
    //         y: '-25%',
    //         autoAlpha: 0,
    //         ease: Power0.easeNone,
    //     }, 0);

    // new ScrollMagic.Scene({
    //     triggerElement: '.can-do',
    //     triggerHook: 0.99,
    //     duration: '30%',
    // })
    //     .setTween(tlHero)
    //     .addTo(controller);

    /* * Section Can do * */

    // const tlCanDoImg = new TimelineMax()
    //     .fromTo('.can-do .can-do__img', 0.5, {
    //         autoAlpha: 0,
    //         y: '10%',
    //         x: '7%',
    //         ease: Power0.easeNone,
    //     }, {
    //         autoAlpha: 1,
    //         y: '0%',
    //         x: '7%',
    //         ease: Power0.easeNone,
    //     }, 0.1);
    // new ScrollMagic.Scene({
    //     triggerElement: '.can-do',
    //     triggerHook: 0.5,
    //     duration: '30%',
    // })
    //     .setTween(tlCanDoImg)
    //     .addTo(controller);

    // const tlCanDoList = new TimelineMax()
    //     .staggerFromTo('.can-do__list-item', 0.8, {
    //         autoAlpha: 0,
    //         y: '30%',
    //         ease: Power0.easeNone,
    //     }, {
    //         autoAlpha: 1,
    //         y: '0%',
    //         ease: Power0.easeNone,
    //     }, 0.1);
    // new ScrollMagic.Scene({
    //     triggerElement: '.can-do',
    //     triggerHook: 0.2,
    //     duration: '30%',
    // })
    //     .setTween(tlCanDoList)
    //     .addTo(controller);

    const tlIngr1 = new TimelineMax({
        repeat: -1,
        repeatDelay: 1,
    })
        .to('.bounce-shape', 1.5, {
            bezier: {
                curviness: 0,
                values: [{
                    x: '+=0',
                    y: '-=10',
                },
                {
                    x: '+=3',
                    y: '-=15',
                },
                {
                    x: '+=5',
                    y: '-=20',
                },
                {
                    x: '+=5',
                    y: '-=25',
                },
                {
                    x: '+=3',
                    y: '-=30',
                },
                ],
            },
            scaleX: 1.3,
            scaleY: 1.3,
            ease: Power1.easeIn,
        })
        .to('.bounce-shape', 0.4, {
            autoAlpha: 0,
        });
    const tlIngr2 = new TimelineMax({
        repeat: -1,
        repeatDelay: 1,
    })
        .to('.bounce-shape1', 2, {
            bezier: {
                curviness: 0,
                values: [{
                    x: '+=10',
                    y: '-=10',
                },
                {
                    x: '+=13',
                    y: '-=13',
                },
                {
                    x: '+=15',
                    y: '-=17',
                },
                {
                    x: '+=15',
                    y: '-=20',
                },
                {
                    x: '+=13',
                    y: '-=25',
                },
                ],
            },
            scaleX: 0.9,
            scaleY: 0.9,
            ease: Power0.easeNone,
        })
        .to('.bounce-shape1', 0.4, {
            autoAlpha: 0,
        });
    const tlIngr3 = new TimelineMax({
        repeat: -1,
        repeatDelay: 1,
    })
        .to('.bounce-shape2', 2, {
            bezier: {
                curviness: 0,
                values: [{
                    x: '-=10',
                    y: '-=20',
                },
                {
                    x: '-=13',
                    y: '-=23',
                },
                {
                    x: '-=15',
                    y: '-=27',
                },
                {
                    x: '-=15',
                    y: '-=30',
                },
                {
                    x: '-=13',
                    y: '-=35',
                },
                ],
            },
            scaleX: 0.9,
            scaleY: 0.9,
            ease: Power0.easeNone,
        })
        .to('.bounce-shape2', 0.4, {
            autoAlpha: 0,
        });
    const tlIngr4 = new TimelineMax({
        repeat: -1,
        repeatDelay: 1,
    })
        .to('.bounce-shape3', 3, {
            bezier: {
                curviness: 0,
                values: [{
                    x: '-=10',
                    y: '-=20',
                },
                {
                    x: '-=13',
                    y: '-=23',
                },
                {
                    x: '-=15',
                    y: '-=27',
                },
                {
                    x: '-=15',
                    y: '-=30',
                },
                {
                    x: '-=13',
                    y: '-=35',
                },
                ],
            },
            scaleX: 0.9,
            scaleY: 0.9,
            ease: Power0.easeNone,
        })
        .to('.bounce-shape3', 0.4, {
            autoAlpha: 0,
        });
    const tlIngr5 = new TimelineMax({
        repeat: -1,
        repeatDelay: 1,
    })
        .to('.bounce-shape4', 2.5, {
            bezier: {
                curviness: 0,
                values: [{
                    x: '-=10',
                    y: '+=2',
                },
                {
                    x: '-=13',
                    y: '+=1',
                },
                {
                    x: '-=15',
                    y: '-=0',
                },
                {
                    x: '-=18',
                    y: '+=2',
                },
                {
                    x: '-=20',
                    y: '+=3',
                },
                ],
            },
            scaleX: 0.9,
            scaleY: 0.9,
            rotation: 360,
            transformOrigin: 'center center',
            ease: Power0.easeNone,
        })
        .to('.bounce-shape4', 0.4, {
            autoAlpha: 0,
        });
    const tlIngr6 = new TimelineMax({
        repeat: -1,
        repeatDelay: 1,
    })
        .to('.bounce-shape5', 3, {
            bezier: {
                curviness: 0,
                values: [{
                    x: '+=10',
                    y: '-=20',
                },
                {
                    x: '+=13',
                    y: '-=27',
                },
                {
                    x: '+=15',
                    y: '-=32',
                },
                {
                    x: '+=17',
                    y: '-=40',
                },
                {
                    x: '+=25',
                    y: '-=55',
                },
                ],
            },
            rotation: 150,
            transformOrigin: '50% 50%',
            scaleX: 0.9,
            scaleY: 0.9,
            ease: Power0.easeNone,
        })
        .to('.bounce-shape5', 0.4, {
            autoAlpha: 0,
        });
    const options = {
        transformOrigin: '50% 50%',
        scaleX: 1.06,
        scaleY: 1.06,
        autoAlpha: 0,
        ease: Power0.easeNone,
    };


    const tlIngrFrame = new TimelineMax({
        repeat: -1,
        repeatDelay: 0,
    })
        .fromTo('.ingr1 .ingr-frame, .ingr1 .ingr-frame1', 0.2, {
            transformOrigin: '50% 50%',
            scaleX: 0.9,
            scaleY: 0.9,
            autoAlpha: 0,
            ease: Power0.easeNone,
        }, {
            autoAlpha: 1,
        })
        .to('.ingr1 .ingr-frame, .ingr1 .ingr-frame1', 1.6, {
            transformOrigin: '50% 50%',
            scaleX: 1.07,
            scaleY: 1.07,
            autoAlpha: 0,
            ease: Power0.easeNone,
        });

    const tlIngrFrame1 = new TimelineMax({
        repeat: -1,
        repeatDelay: 0,
    })
        .fromTo('.ingr2 .ingr-frame, .ingr2 .ingr-frame1', 0.2, {
            transformOrigin: '50% 50%',
            scaleX: 0.9,
            scaleY: 0.9,
            autoAlpha: 0,
            ease: Power0.easeNone,
        }, {
            autoAlpha: 1,
        })
        .to('.ingr2 .ingr-frame, .ingr2 .ingr-frame1', 1.2, {
            transformOrigin: '50% 50%',
            scaleX: 1.07,
            scaleY: 1.07,
            autoAlpha: 0,
            ease: Power0.easeNone,
        });
    const tlIngrFrame3 = new TimelineMax({
        repeat: -1,
        repeatDelay: 0,
    })
        .fromTo('.ingr3 .ingr-frame, .ingr3 .ingr-frame1', 0.2, {
            transformOrigin: '50% 50%',
            scaleX: 0.9,
            scaleY: 0.9,
            autoAlpha: 0,
            ease: Power0.easeNone,
        }, {
            autoAlpha: 1,
        })
        .to('.ingr3 .ingr-frame, .ingr3 .ingr-frame1', 2, {
            transformOrigin: '50% 50%',
            scaleX: 1.07,
            scaleY: 1.07,
            autoAlpha: 0,
            ease: Power0.easeNone,
        });


    const tlLampGradient = new TimelineMax()
        .staggerTo('.descr-top-img svg stop', 1, {
            cycle: {
                stopColor: ['#fceb50', '#fceb50', '#fceb50', '#fceb50', '#fceb50', '#fceb50'],
            },
        }, 0.1, 0);
    new ScrollMagic.Scene({
        triggerElement: '.descr-top-img',
        triggerHook: 0.5,
        duration: '100%',
    })
        .setTween(tlLampGradient)
        .addTo(controller);





    $('.test-item').each(function () {
        const tl = new TimelineLite();
        const tl1 = new TimelineLite();
        const tl2 = new TimelineLite();
        const el = $(this);
        const title = el.find('.test-item-content .test-title');
        const text = el.find('.test-item-content p');
        // const img = el.find('.test__item-img');
        new ScrollMagic.Scene({
            triggerElement: this,
            triggerHook: 0.8,
            duration: '40%',
        })
            .setTween(tl.fromTo(title, 1, {
                y: '10%',
                opacity: 0,
                ease: Power0.easeNone,
            }, {
                y: '0%',
                opacity: 1,
                ease: Power0.easeNone,
            }))
            .addTo(controller);
        new ScrollMagic.Scene({
            triggerElement: this,
            triggerHook: 0.7,
            duration: '40%',
        })
            .setTween(tl1.fromTo(text, 1, {
                y: '20%',
                opacity: 0,
                ease: Power0.easeNone,
            }, {
                y: '0%',
                opacity: 1,
                ease: Power0.easeNone,
            }))
            .addTo(controller);
    });

    const tlTestItem = new TimelineMax({
        repeat: -1,
        yoyo: true,
        // repeatDelay: 1
    })
        .to('.test-item1', 3, {
            scaleX: 1.03,
            scaleY: 1.03,
            x: '+=10',
            y: '-=10',
            rotation: 10,
            transformOrigin: 'center center',
            ease: Power1.easeIn,
        })
        .to('.test-item1', 3, {
            scaleX: 1.03,
            scaleY: 1.03,
            x: '-=10',
            y: '+=10',
            rotation: -10,
            transformOrigin: 'center center',
            ease: Power1.easeIn,
        });
    const tlTestItem1 = new TimelineMax({
        repeat: -1,
        yoyo: true,
    })
        .to('.test-item1-1', 3, {
            scaleX: 1.03,
            scaleY: 1.03,
            x: '+=10',
            y: '-=10',
            rotation: 360,
            transformOrigin: 'center center',
            ease: Power1.easeIn,
        })
        .to('.test-item1-1', 3, {
            scaleX: 1.03,
            scaleY: 1.03,
            x: '-=10',
            y: '+=10',
            rotation: 360,
            transformOrigin: 'center center',
            ease: Power1.easeIn,
        });

    const tlTestItem2 = new TimelineMax({
        repeat: -1,
        yoyo: true,
        // repeatDelay: 1
    })
        .to('.test-item2', 4, {
            scaleX: 1.03,
            scaleY: 1.03,
            x: '-=10',
            y: '-=15',
            rotation: 10,
            transformOrigin: 'center center',
            ease: Power1.easeIn,
        })
        .to('.test-item2', 4, {
            scaleX: 1.03,
            scaleY: 1.03,
            x: '-=10',
            y: '+=15',
            rotation: -10,
            transformOrigin: 'center center',
            ease: Power1.easeIn,
        });
    const tlTestItem3 = new TimelineMax({
        repeat: -1,
        yoyo: true,
    })
        .to('.test-item2-2', 4, {
            scaleX: 1.03,
            scaleY: 1.03,
            x: '-=10',
            y: '-=10',
            rotation: 360,
            transformOrigin: 'center center',
            ease: Power1.easeIn,
        })
        .to('.test-item2-2', 4, {
            scaleX: 1.03,
            scaleY: 1.03,
            x: '-=10',
            y: '+=10',
            rotation: 360,
            transformOrigin: 'center center',
            ease: Power1.easeIn,
        });
    const tlTestItem4 = new TimelineMax({
        repeat: -1,
        yoyo: true,
        // repeatDelay: 1
    })
        .to('.test-item3', 3.5, {
            scaleX: 1.03,
            scaleY: 1.03,
            x: '-=10',
            y: '-=15',
            transformOrigin: 'center center',
            ease: Power1.easeIn,
        })
        .to('.test-item3', 3.5, {
            scaleX: 1.03,
            scaleY: 1.03,
            x: '-=10',
            y: '+=15',
            transformOrigin: 'center center',
            ease: Power1.easeIn,
        });
    const tlTestItem5 = new TimelineMax({
        repeat: -1,
        yoyo: true,
    })
        .to('.test-item3-2', 3.5, {
            scaleX: 1.03,
            scaleY: 1.03,
            x: '-=10',
            y: '-=10',
            rotation: 360,
            transformOrigin: 'center center',
            ease: Power1.easeIn,
        })
        .to('.test-item3-2', 3.5, {
            scaleX: 1.03,
            scaleY: 1.03,
            x: '-=10',
            y: '+=10',
            rotation: 360,
            transformOrigin: 'center center',
            ease: Power1.easeIn,
        });
    const tlTestCircle = new TimelineMax({
        repeat: -1,
        repeatDelay: 0,
        // yoyo: true
    })
        .to('.test-circle', 3.5, {
            rotation: '360',
            transformOrigin: 'center center',
            ease: Power0.easeNone,
        });

    const tlTestBatchBelt = new TimelineMax({
        // repeat: -1,
        // repeatDelay: 0
        // yoyo: true
    })
        .to('.test-batch__belt .first', 7, {
            x: '-100%',
            ease: Power0.easeNone,
        })
        .set('.test-batch__belt .first', {
            autoAlpha: 0,
            ease: Power0.easeNone,
        })
        .to('.test-batch__belt .sec', 14, {
            x: '-100%',
            repeat: -1,
            ease: Power0.easeNone,
        }, '-=7')
        .to('.test-batch__belt .third', 14, {
            x: '-100%',
            repeat: -1,
            ease: Power0.easeNone,
        }, '-=7');
    const tlTestBatchBottle = new TimelineMax({
        repeat: -1,
        repeatDelay: 1,
        // yoyo: true
    })

        .to('.test-batch__bottle--mask', 1, {
            autoAlpha: 1,
            ease: Power0.easeNone,
            // repeat: -1,
        })
        .to('.test-batch__check', 0.5, {
            autoAlpha: 1,
            ease: Power3.easeInOut,
            // repeat: -1,
        }, '+=0.5')
        .to('.test-batch__check', 0.5, {
            autoAlpha: 0,
            ease: Power3.easeInOut,
            // repeat: -1,
        }, '+=0.5')
        .to('.test-batch__bottle--mask', 0.5, {
            x: '-160%',
            ease: Power0.easeNone,
            // repeat: -1,
        });

    function animateTestBscg() {
        const firstBox = $('.test-bscg__left img:first-child');
        const secBox = $('.test-bscg__left img:nth-child(2)');
        const lastBox = $('.test-bscg__left img:last-child');
        const rightBox = $('.test-bscg__right img:first-child');
        const rightBoxMiddle = $('.test-bscg__right img:nth-child(2)');
        const rightBoxLast = $('.test-bscg__right img:last-child');
        const tlTestBscg = new TimelineMax({})

            .to('.test-bscg__box', 0.5, {
                top: '10%',
                ease: Power0.easeNone,
            })
            .to('.test-bscg__box', 0.3, {
                top: '+=15%',
                autoAlpha: 1,
                ease: Power0.easeIn,
                // repeat: -1,
            })
            .set('.test-bscg__line', {
                // top: '+=5%',
                autoAlpha: 1,
                ease: Power0.easeNone,
                // repeat: -1,
            })
            // 54.5-25
            // 54.1
            .to('.test-bscg__box', 0.8, {
                // top: '+=23.5%',
                top: '+=31.1%',
                autoAlpha: 1,
                ease: Power4.ease,
                // repeat: -1,
            })
            .set('.test-bscg__line', {
                // top: '+=5%',
                autoAlpha: 0,
                ease: Power0.easeNone,
                // repeat: -1,
            })
            .set('.test-bscg__dump', {
                // top: '+=5%',
                autoAlpha: 1,
                ease: Power0.easeNone,
                // repeat: -1,
            })
            .to('.test-bscg__box', 0.8, {
                top: '-=.5%',
                autoAlpha: 1,
                ease: Power4.ease,
                // repeat: -1,
            })
            .to('.test-bscg__dump', 0.5, {
                // top: '+=5%',
                autoAlpha: 0,
                ease: Power0.easeNone,
                // repeat: -1,
            }, '-=0.6')

            .to('.test-bscg__box', 0.8, {
                top: '+=.5%',
                autoAlpha: 1,
                ease: Power4.ease,
                // repeat: -1,
            })
            .set('.test-bscg__box', {

                autoAlpha: 0,
                ease: Power4.ease,
                // repeat: -1,
            })
            .set(firstBox, {

                autoAlpha: 1,
                // ease: Power4.ease,
                // repeat: -1,
            }, '-=0.2')
            .to(firstBox, 0.8, {
                left: '33%',
                // autoAlpha: 0,
                ease: Power4.ease,
                // repeat: -1,
            })
            .to(secBox, 0.8, {
                left: '66%',
                // autoAlpha: 0,
                ease: Power4.ease,
                // repeat: -1,
            }, '-=0.8')
            .to(lastBox, 0.8, {
                left: '99%',
                // autoAlpha: 0,
                ease: Power4.ease,
                // repeat: -1,
            }, '-=0.8')
            .to(rightBox, 0.8, {
                left: '0%',
                // autoAlpha: 0,
                ease: Power4.ease,
                // repeat: -1,
            }, '-=0.8')
            .to(rightBoxMiddle, 0.8, {
                left: '43%',
                ease: Power4.ease,
                // repeat: -1,
            }, '-=0.8')
            .to(rightBoxLast, 0.8, {
                left: '86%',
                autoAlpha: 0,
                ease: Power4.ease,
                // repeat: -1,
            }, '-=0.8')
            .add(() => {
                $('.test-bscg__left img:last-child').remove();
                $('.test-bscg__left').prepend('<img src="/images/box.svg" alt="/" style="">');
                $('.test-bscg__right img:last-child').remove();
                $('.test-bscg__right').prepend('<img src="/images/box-check.svg" alt="/" style="">');
            });
    }

    animateTestBscg();
    setInterval(() => {
        TweenMax.delayedCall(1, animateTestBscg);
    }, 5000);

    tl7
        .staggerTo('.footer svg stop', 1, {
            cycle: {
                stopColor: ['#fceb50', '#fceb50', '#fceb50', '#fceb50', '#fceb50', '#fceb50'],
            },
        }, 0.1, 0);
    new ScrollMagic.Scene({
        triggerElement: '.footer',
        triggerHook: 0.5,
        duration: '100%',
    })
        .setTween(tl7)
        .addTo(controller);
    tl8
        .to('.footer__img1', 1, {
            y: 0,
        });
    new ScrollMagic.Scene({
        triggerElement: '.footer',
        triggerHook: 0.3,
        duration: '100%',
    })
        .setTween(tl8)
        .addTo(controller);
    tl9
        .to('.footer__img3', 1, {
            y: 0,
        });
    new ScrollMagic.Scene({
        triggerElement: '.footer__img2',
        triggerHook: 0.5,
        // offset: '50%'
        duration: '100%',
    })
        .setTween(tl9)
        .addTo(controller);

    const tlTestStability = new TimelineMax()
        .to('#theLine', 0.7, {
            attr: {
                y2: 796,
            },
            ease: Linear.easeNone,
        });
    new ScrollMagic.Scene({
        triggerElement: '.test-stability',
        triggerHook: 0.4,
        duration: '100%',
    })
        .setTween(tlTestStability)
        .addTo(controller);

    $('.test-stability-table').each(function () {
        const tlTestStability2 = new TimelineMax()
            .to(this, 0.2, {
                attr: {
                    fill: '#69CBE2',
                },
            });
        new ScrollMagic.Scene({
            triggerElement: this,
            triggerHook: 0.6,
            // duration: '50%',
        })
            .setTween(tlTestStability2)
            .addTo(controller);
    });


    $('.test-stability__box').each(function () {
        const boxInside = $(this).find('.box-inside');
        const boxBorder = $(this).find('.box-border');
        const boxLine = $(this).find('.box-line');
        const boxLogo = $(this).find('.box-logo');
        const boxCheckBorder = $(this).find('.box-check-border');
        const boxCheckIcon = $(this).find('.box-check-icon');
        const tlTestStabilityBox = new TimelineMax()
            .fromTo(boxCheckBorder, 0.05, {
                attr: {
                    fill: '#f2eee4',
                    stroke: '#e0d8c3',
                },
            }, {
                attr: {
                    fill: '#69cbe2',
                    stroke: '#456e79',
                },
            }, 0.1)
            .fromTo(boxCheckIcon, 0.1, {
                attr: {
                    fill: '#e0d8c3',
                },
            }, {
                attr: {
                    fill: '#fff',
                },
            }, '-=0.1')
            .fromTo(boxInside, 0.1, {
                attr: {
                    fill: '#f2eee4',
                },
            }, {
                attr: {
                    fill: '#69cbe2',
                },
            }, -0.1)
            .fromTo(boxBorder, 0.2, {
                attr: {
                    fill: '#e0d8c3',
                },
            }, {
                attr: {
                    fill: '#456e79',
                },
            }, -0.1)
            .fromTo(boxLine, 0.1, {
                attr: {
                    fill: 'rgb(224, 216, 195)',
                },
            }, {
                attr: {
                    fill: '#456e79',
                },
            }, -0.1)
            .fromTo(boxLogo, 0.1, {
                attr: {
                    fill: 'rgb(224, 216, 195)',
                },
            }, {
                attr: {
                    fill: 'rgb(69, 110, 121)',
                },
            }, -0.1);
        new ScrollMagic.Scene({
            triggerElement: this,
            triggerHook: 0.6,
            // duration: '50%',
        })
            .setTween(tlTestStabilityBox)
            .addTo(controller);
    });
};


const tlTablet1 = new TimelineMax({
    // repeat: -1,
    // yoyo: true
})

    .to('#Group_7273-42', 3, {
        y: '+=30',
        x: '-=5',
        rotation: 360,
        transformOrigin: 'center center',
        autoAlpha: 0.1,
        repeat: -1,
        //  yoyo: true
    });
const tlTablet2 = new TimelineMax({})

    .fromTo('#Group_7273-43', 2.5, {
        y: '-=5',
        x: '+=0',
        // rotation: 360,
        transformOrigin: 'center center',
        autoAlpha: 1,
        repeat: -1,
        //  yoyo: true
    }, {
        y: '+=30',
        x: '-=5',
        rotation: 360,
        transformOrigin: 'center center',
        autoAlpha: 0.1,
        repeat: -1,
        //  yoyo: true
    });
const tlTablet3 = new TimelineMax({})

    .fromTo('#Group_7273-44', 3, {
        y: '-=14',
        x: '+=5',
        // rotation: 360,
        transformOrigin: 'center center',
        autoAlpha: 1,
        repeat: -1,
        //  yoyo: true
    }, {
        y: '+=30',
        x: '-=2',
        rotation: -300,
        transformOrigin: 'center center',
        autoAlpha: 0.1,
        repeat: -1,
        //  yoyo: true
    }, 0.5);
const tlTablet4 = new TimelineMax({})

    .fromTo('#Group_7273-45', 4, {
        y: '-=20',
        x: '+=5',
        // rotation: 360,
        transformOrigin: 'center center',
        autoAlpha: 1,
        repeat: -1,
        //  yoyo: true
    }, {
        y: '+=35',
        x: '-=5',
        rotation: 300,
        transformOrigin: 'center center',
        autoAlpha: 0.1,
        repeat: -1,
        //  yoyo: true
    }, 0.3);

(function () {

    const checkoutData = {
        'oneTime': {
            'one': {
                // 'regularPrice': '$38.00',
                // 'savings': '$1.00',
                'price': '$38.00',
                'period': '(This is a one time payment)',
                'link': 'https://hpn-supplements.myshopify.com/cart/21174522675284:1'
            },
            'three': {
                'regularPrice': '$114.00',
                'savings': '$12.00',
                'price': '$102.00',
                'period': '(This is a one time payment)',
                'link': 'https://hpn-supplements.myshopify.com/cart/21174522708052:1'
            },
            'six': {
                'regularPrice': '$228.00',
                'savings': '$48.00',
                'price': '$180.00',
                'period': '(This is a one time payment)',
                'link': 'https://hpn-supplements.myshopify.com/cart/21174522740820:1'
            }
        },
        'subscribe': {
            'one': {
                'regularPrice': '$38.00',
                'savings': '$3.00',
                'price': '$35.00',
                'period': '(This is a reoccuring payment)',
                'link': 'https://hpn-supplements.myshopify.com/tools/checkout/buy_button/add_to_cart?p=2349097484372&v=21174522675284&g=81531&fn=1&ft=3&dp=$35.00&rdp=0.921&rup=3500'
            },
            'three': {
                'regularPrice': '$114.00',
                'savings': '$20.06',
                'price': '$93.94',
                'period': '(This is a reoccuring payment)',
                'link': 'https://hpn-supplements.myshopify.com/tools/checkout/buy_button/add_to_cart?p=2349097484372&v=21174522708052&g=81531&fn=1&ft=3&dp=$93.94&rdp=0.921&rup=9394'
            },
            'six': {
                'regularPrice': '$228.00',
                'savings': '$62.22',
                'price': '$165.78',
                'period': '(This is a reoccuring payment)',
                'link': 'https://hpn-supplements.myshopify.com/tools/checkout/buy_button/add_to_cart?p=2349097484372&v=21174522740820&g=81531&fn=1&ft=3&dp=$165.78&rdp=0.921&rup=16578'
            }
        }
    }

    let subscribe = false;
    let qwt = $('.js-checkout-variant').data('qwt')

    function getData(qwt, plan) {
        if (plan) {
            return checkoutData.subscribe[qwt]
        }
        return checkoutData.oneTime[qwt]
    }

    function showCurrentData(obj) {
        if(obj.regularPrice){
            $('.checkout-card__regular').show()
            $('.js-checkout-regular').text(obj.regularPrice)
        }else{
            $('.checkout-card__regular').hide()
        }
        if(obj.savings){
            $('.checkout-card__saving--instant').show()
            $('.js-checkout-saving').text(obj.savings)
        }else{
            $('.checkout-card__saving--instant').hide()
            $('.js-checkout-saving').text('There are currently no saving available for a single bottle')
        }
        if(subscribe){
            $('.checkout-card__saving--instant').hide()
        }

        $('.js-checkout-price').text(obj.price)
        $('.js-checkout-period').text(obj.period)
        $('.js-checkout-link').attr('href', obj.link)
    }

    $('.js-subscribe-btn').click(function (e) {

        e.preventDefault();

        $('.checkout-cards').toggleClass('is-subscribed');

        subscribe = !subscribe;

        const currentData = getData(qwt, subscribe)
        showCurrentData(currentData)


    });

    $('.js-checkout-variant').click(function (e) {

        e.preventDefault();

        $('.js-checkout-variant').removeClass('is-active')
        $(this).addClass('is-active');
        $(this).closest('.checkout-card').find('.checkout-card__header').removeClass('one three  six')
        $(this).closest('.checkout-card').find('.checkout-card__header').addClass($(this).data('qwt'))


        qwt = $(this).data('qwt');

        const currentData = getData(qwt, subscribe)
        showCurrentData(currentData)
    });

})();



$(document).on('click', '.js-scroll-to', function () {
    const scroll = $(this).attr('data-scroll');
    let speed = parseInt($(this).attr('data-speed'), 10);
    if (isNaN(speed)) {
        speed = +1200;
    }
    $.scrollTo('#js-' + scroll + '', speed, {offset:0});
    return false;
})

$(document).ready(function () {
    const linkHash = window.location.hash.replace("#", "");

    if (linkHash === 'checkout') {
        $('.checkout-page').addClass('is-show');
        $('body').addClass('order-show');
    }

})


$('.js-open-checkout').click(function(e){
    e.preventDefault();
    $('.checkout-page').addClass('is-show');
    $('body').addClass('order-show');
    $('.modal').modal('hide')
    console.log('done');
})
$('.js-close-checkout').click(function(e){
    e.preventDefault();
    $('.checkout-page').removeClass('is-show');
    $('body').removeClass('order-show')
})
animateCard();
reviewSlider();
animateGreetScreen();
animateSite();
cardSlider()
// function isMobile() {
//     var a = (navigator.userAgent || navigator.vendor || window.opera);
//     if (/android.+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od|ad)|iris|kindle|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(di|rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) {
//         return true;
//     }
//     return false;
// }

// if (isMobile() === true) {
//     window.location = '/smart.html';
// }

$('.js-scroll-checkout').click(function(e){
    e.preventDefault()
    // console.log('done');
    // $('.checkout-page').scrollTo(0,0)
    $('.checkout-page').scrollTo('.checkout-hero-title', 900);

})